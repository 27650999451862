import loadscript from "@afuggini/loadscript";
import { useState } from "preact/hooks";
import { createContainer } from "unstated-next";
import texts from "./texts.json";
import * as Comlink from "comlinkjs";

const MyWorker = Comlink.proxy(new Worker("/worker.js"));

const localTexts = () => {
  const short = navigator.language.split(/\-/g)[0];
  const code = short === "zh" ? navigator.language : short;
  return texts[code];
};

export const useStore = () => {
  const [initCompleted, setInitCompleted] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [keyStatus, setKeyStatus] = useState(0);
  const [publicKeyEncoded, setPublicKeyEncoded] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [pickedFileName, setPickedFileName] = useState(null);
  const [randomName, setRandomName] = useState(null);
  const [originalName, setOriginalName] = useState(null);
  const [mimeType, setMimeType] = useState(null);
  const [emailRestored, setEmailRestored] = useState(null);
  const [keyEncrypted, setKeyEncrypted] = useState(null);
  const [error, setError] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState(0);

  window.onSignIn = async googleUser => {
    // console.log(googleUser);
    if (gapi.auth2.getAuthInstance()) {
      gapi.auth2.getAuthInstance().isSignedIn.listen(isSignedIn => {
        setInitCompleted(isSignedIn);
      });
    }

    await gapi.load("client", {
      callback: async () => {
        gapi.client.init({
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
          ]
        });
        setInitCompleted(true);
      }
    });
  };

  const loadGapi = ({ action }) => {
    loadscript("https://apis.google.com/js/api.js").then(async () => {
      //   await new Promise(resolve => setTimeout(resolve, 3000));
      const clientId =
        "424331932285-rm2vuvpe7b3lu5uhgu2g5d0g2pp73m2b.apps.googleusercontent.com";

      await gapi.load("client", {
        callback: async () => {
          if (gapi.auth2.getAuthInstance()) {
            gapi.auth2.getAuthInstance().isSignedIn.listen(isSignedIn => {
              setInitCompleted(isSignedIn);
            });
          }

          if (
            action === "SIGNOUT" &&
            gapi.auth2.getAuthInstance().isSignedIn.get()
          ) {
            gapi.auth2.getAuthInstance().signOut();
            return;
          }

          if (action === "SIGNIN") {
            const auth = await gapi.auth2.init({
              client_id: clientId,
              scope: "https://www.googleapis.com/auth/drive",
              ux_mode: "popup"
            });

            if (!auth.isSignedIn || !gapi.client.getToken()) {
              auth.signIn().catch(err => {});
            } else {
              gapi.client.init({
                discoveryDocs: [
                  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
                ]
              });
              setInitCompleted(true);
            }
          }
        }
      });
    });
  };

  const signOut = async () => {
    loadGapi({ action: "SIGNOUT" });
  };

  const init = async () => {
    // loadGapi({ action: "SIGNIN" });

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("differ", "");
    script.src = "https://apis.google.com/js/platform.js";
    document.head.append(script);
  };

  const upload = async ({ target }) => {
    setUploadCompleted(false);
    const { drive, getToken } = gapi.client;
    const { access_token } = getToken();
    const email = gapi.auth2
      .getAuthInstance()
      .currentUser.get()
      .getBasicProfile()
      .getEmail();

    Promise.all(
      Array.from(target.files).map(async file => {
        setPickedFileName(file.name);
        const fileReader = new FileReader();
        fileReader.onloadend = async ({ target }) => {
          const { type, name } = file;
          const blob = new Blob([target.result], { type });

          const url = URL.createObjectURL(blob);

          const instance = await new MyWorker();

          await instance.encrypt(
            { url, access_token, email, name, type, publicKeyEncoded },
            Comlink.proxyValue(({ fileId }) => {
              setUploadCompleted(true);
              setFileId(fileId);
            }),
            Comlink.proxyValue(progress => {
              console.log(progress);
            })
          );
        };
        fileReader.readAsArrayBuffer(file);
      })
    );
  };

  const createKey = async () => {
    setPublicKeyEncoded(null);
    setKeyStatus(1);
    const instance = await new MyWorker();

    await instance.createKeypair(
      Comlink.proxyValue(myPublicKeyEncoded => {
        setPublicKeyEncoded(myPublicKeyEncoded);
        setKeyStatus(2);
      })
    );
  };

  const restorePublicKey = myPublicKeyEncoded => {
    setPublicKeyEncoded(myPublicKeyEncoded);
  };

  const getMeta = async fileId => {
    const { drive, getToken } = gapi.client;
    const { access_token } = getToken();

    const instance = await new MyWorker();

    // mimeType,
    // publicKeyEncoded,
    // originalName,
    // emailRestored: email,
    // randomName: result.name,
    // keyEncrypted

    await instance.getMeta(
      { fileId, access_token },
      Comlink.proxyValue(
        ({
          originalName,
          mimeType,
          emailRestored,
          publicKeyEncoded,
          randomName,
          keyEncrypted,
          error
        }) => {
          setOriginalName(originalName);
          setMimeType(mimeType);
          setEmailRestored(emailRestored);
          setRandomName(randomName);
          setPublicKeyEncoded(publicKeyEncoded);
          setKeyEncrypted(keyEncrypted);
          setError(error);
        }
      )
    );
  };

  const download = async fileId => {
    const { drive, getToken } = gapi.client;
    const { access_token } = getToken();

    const instance = await new MyWorker();

    await instance.download(
      {
        fileId,
        access_token,
        originalName,
        mimeType,
        keyEncrypted,
        publicKeyEncoded
      },
      Comlink.proxyValue(({ url }) => {
        const el = document.createElement("a");
        el.href = url;
        el.download = originalName;
        el.click();
      }),
      Comlink.proxyValue(({ downloadStatus }) => {
        setDownloadStatus(downloadStatus);
      })
    );
  };

  return {
    init,
    initCompleted,
    signOut,
    upload,
    createKey,
    publicKeyEncoded,
    keyStatus,
    restorePublicKey,
    fileId,
    download,
    pickedFileName,
    randomName,
    localTexts: localTexts(),
    getMeta,
    emailRestored,
    originalName,
    uploadCompleted,
    error,
    downloadStatus
  };
};
export const StoreContainer = createContainer(useStore);
