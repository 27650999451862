import "./style";
import "bulma/css/bulma.min.css";

import App from "./components/app";
import { StoreContainer } from "./store";

export default function() {
  return (
    <StoreContainer.Provider>
      <App></App>
    </StoreContainer.Provider>
  );
}
