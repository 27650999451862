import { h, Component } from "preact";
import { Router } from "preact-router";
import { StoreContainer } from "../store";

import Header from "./header";

// Code-splitting is automated for routes
import Home from "../routes/home";
import Profile from "../routes/profile";
import Upload from "../routes/upload";
import Download from "../routes/download";
import { useState, useEffect } from "preact/hooks";
import { IntlProvider } from "preact-i18n";

export default function App() {
  const [currentUrl, setCurrentUrl] = useState(null);

  const store = StoreContainer.useContainer();
  const { localTexts } = store;
  const handleRoute = e => {
    setCurrentUrl(e.url);
  };

  return (
    <IntlProvider definition={localTexts}>
      <div id="app">
        {/* <Header /> */}
        <Router onChange={handleRoute}>
          <Home path="/" />
          <Upload path="/up/" />

          <Upload path="/up/:publicKeyEncoded" />
          <Download path="/down/:fileId" />
          <Profile path="/profile/" user="me" />
          <Profile path="/profile/:user" />
        </Router>
      </div>
    </IntlProvider>
  );
}
